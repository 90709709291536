import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.includes.js";
import cookies from 'js-cookie';
var kpn = cookies.get('kpn');
export var isKwai = kpn === 'KWAI';
export var isSnack = kpn === 'KWAI_BULLDOG';
export var isKwaiMe = kpn === 'KWAI.ME';
export var isInApp = ['KWAI', 'KWAI_BULLDOG', 'KWAI.ME'].includes(kpn);
export var isYodaEnv = /yoda/i.test(navigator.userAgent);
export var NOT_LOGIN_RES_CODE = [109, 6002];
var device = {
  isIOS: function isIOS(ua) {
    return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  },
  isAndroid: function isAndroid(ua) {
    return ua.includes('Android') || ua.includes('Adr');
  },
  isLogin: function isLogin(ua) {
    var token = cookies.get('token');
    var ud = cookies.get('ud');
    return (
      // eslint-disable-next-line no-mixed-operators
      device.isIOS(ua) && !!token || device.isAndroid(ua) && ud !== '0' && !!ud
    );
  },
  isInApp: function isInApp() {
    var KPN = cookies.get('kpn') || '';
    return ['KWAI', 'KWAI_BULLDOG', 'KWAI.ME'].includes(KPN);
  }
};
export var deviceInfo = function deviceInfo() {
  var ua = navigator.userAgent;
  return {
    isIOS: device.isIOS(ua),
    isAndroid: device.isAndroid(ua),
    isPhone: device.isIOS(ua) || device.isAndroid(ua),
    isLogin: device.isLogin(ua),
    isInApp: device.isInApp(),
    kpn: cookies.get('kpn')
  };
};
export var isProduction = process.env.NODE_ENV === 'production';
export var mProKwaiOrigin = process.env.HOST || window.location.origin;
console.log('env', process.env.NODE_ENV);
export var getCurrentOneLinkUrl = function getCurrentOneLinkUrl() {
  if (isSnack) return 'https://snackvideoactivity.onelink.me/1i1L/client';
  if (isKwaiMe) return 'https://kwaime.onelink.me/V16i/client';
  return 'https://go.onelink.me/iGO2/client';
};
// ua字段
// kwai-me :  Kwai_Pro/ + version
// kwai-pro : Kwai_Pro/ + version
// kwai-snack : Kwai_Bulldog/ + version