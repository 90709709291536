import Vue from 'vue';
import Vuex from 'vuex';
import locale from './modules/locale';
import loginModule from './modules/login';
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    locale: locale,
    login: loginModule
  }
});