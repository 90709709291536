import _objectSpread from "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _Switch from "@kibt/meta-ui/es/switch";
import _Skeleton from "@kibt/meta-ui/es/skeleton";
import _Empty from "@kibt/meta-ui/es/empty";
import _VisibleDir from "@kibt/meta-ui/es/visible/visible-dir";
import _Visible from "@kibt/meta-ui/es/visible";
import _Dialog from "@kibt/meta-ui/es/dialog";
import _Tooltip from "@kibt/meta-ui/es/tooltip";
import _Toast from "@kibt/meta-ui/es/toast";
import _Button from "@kibt/meta-ui/es/button";
import _Navbar from "@kibt/meta-ui/es/navbar";
import _Popup from "@kibt/meta-ui/es/popup";
import "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/core-js/modules/es.array.iterator.js";
import "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/core-js/modules/es.promise.js";
import "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/core-js/modules/es.object.assign.js";
import "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './app.vue';
import router from './router';
import store from './store';
import { i18n } from './utils/k18n';
Vue.use(VueCompositionAPI);
Vue.use(_Popup);
Vue.use(_Navbar);
Vue.use(_Button);
Vue.use(_Toast);
Vue.use(_Tooltip);
Vue.use(_Dialog);
Vue.use(_Visible);
Vue.use(_VisibleDir);
Vue.use(_Empty);
Vue.use(_Skeleton);
Vue.use(_Switch);
Vue.config.productionTip = false;
new Vue({
  i18n: i18n,
  provide: function provide() {
    return _objectSpread({}, i18n);
  },
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');