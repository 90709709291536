import Vue from 'vue';
import { inject } from '@vue/composition-api';
import { createK18n } from '@k18n/vue2-k18n';
import k18nPkg from '@k18n/wallet-k18n';
import { getCurrentLocale } from './getLanguage';
export var i18n = createK18n(Vue, {
  messages: k18nPkg,
  locale: getCurrentLocale()
});
export function useK18n() {
  var $k18n = inject('$k18n');
  return $k18n;
}