import _Row2 from "@ks/kwai-ui/lib/theme-data/row.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Row from "@ks/kwai-ui/lib/row";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import Vue from 'vue';
export default Vue.extend({
  name: 'logo',
  components: {
    Row: _Row
  },
  methods: {
    goHome: function goHome() {
      var path = this.$route.path;
      if (!path.includes('/recharge/index')) {
        this.$router.push({
          path: '/recharge/index'
        });
      }
    }
  }
});