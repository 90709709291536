import _defineProperty from "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.object.to-string.js";
import Cookie from 'js-cookie';
import { GLOBAL_LOCALE_KEY, langsList } from '../../config';
import { getCurrentLocale } from '../../utils/getLanguage';
export var setLocale = 'setLocale';
var ksLocal = GLOBAL_LOCALE_KEY;
var setLocalStorageLocale = function setLocalStorageLocale(value) {
  localStorage.setItem(ksLocal, value);
};
export default {
  namespaced: true,
  state: function state() {
    return {
      current: getCurrentLocale(),
      langs: langsList
    };
  },
  mutations: _defineProperty({}, setLocale, function (state, option) {
    state.current = option;
    setLocalStorageLocale(option);
    Cookie.set(GLOBAL_LOCALE_KEY, option);
    window.location.reload();
    return Promise.resolve();
  }),
  actions: _defineProperty({}, setLocale, function (_ref, payload) {
    var commit = _ref.commit;
    commit(setLocale, payload);
  })
};