import _objectSpread from "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _Avatar2 from "@ks/kwai-ui/lib/theme-data/avatar.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Avatar from "@ks/kwai-ui/lib/avatar";
import _DropdownItem2 from "@ks/kwai-ui/lib/theme-data/dropdown-item.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _DropdownItem from "@ks/kwai-ui/lib/dropdown-item";
import _DropdownMenu2 from "@ks/kwai-ui/lib/theme-data/dropdown-menu.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _DropdownMenu from "@ks/kwai-ui/lib/dropdown-menu";
import _Dropdown2 from "@ks/kwai-ui/lib/theme-data/dropdown.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Dropdown from "@ks/kwai-ui/lib/dropdown";
import _Option2 from "@ks/kwai-ui/lib/theme-data/option.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Option from "@ks/kwai-ui/lib/option";
import _Select2 from "@ks/kwai-ui/lib/theme-data/select.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Select from "@ks/kwai-ui/lib/select";
import _Row2 from "@ks/kwai-ui/lib/theme-data/row.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Row from "@ks/kwai-ui/lib/row";
import Vue from 'vue';
export default Vue.extend({
  name: 'right-content',
  components: {
    Row: _Row,
    Select: _Select,
    Option: _Option,
    Dropdown: _Dropdown,
    DropdownMenu: _DropdownMenu,
    DropdownItem: _DropdownItem,
    Avatar: _Avatar
  },
  inject: ['$k18n'],
  data: function data() {
    return {
      langs: [],
      currentLang: '',
      userInfo: {},
      store: this.$store.state.login
    };
  },
  computed: {
    logined: function logined() {
      return this.$store.state.login.logined;
    }
  },
  created: function created() {
    var _this$$store$state = this.$store.state,
      locale = _this$$store$state.locale,
      login = _this$$store$state.login;
    var langs = locale.langs,
      current = locale.current;
    this.userInfo = _objectSpread({}, login.userInfo);
    this.langs = langs;
    this.currentLang = current;
  },
  methods: {
    onLocaleChange: function onLocaleChange(lang) {
      this.$store.dispatch('locale/setLocale', lang);
    },
    handleCommand: function handleCommand(command) {
      if (command === 'loginout') {
        this.$store.dispatch('login/logout');
      }
    }
  }
});