import { hideLoading } from '@/utils/loading';
import layoutVue from './layout/layout.vue';
import { supportRtlV2 } from './utils/getLanguage';
var visibalHandle = function visibalHandle() {
  // 用户打开或回到页面，关闭loading效果
  if (document.visibilityState === 'visible') {
    hideLoading();
  }
};
export default {
  name: 'app',
  components: {
    layoutVue: layoutVue
  },
  inject: ['$k18n'],
  mounted: function mounted() {
    supportRtlV2();
    document.title = this.$k18n({
      id: 'recharge/document-name'
    });
    document.addEventListener('visibilitychange', visibalHandle);
  },
  beforeUnmount: function beforeUnmount() {
    document.removeEventListener('visibilitychange', visibalHandle);
  }
};