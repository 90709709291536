import _Row2 from "@ks/kwai-ui/lib/theme-data/row.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Row from "@ks/kwai-ui/lib/row";
import _Main2 from "@ks/kwai-ui/lib/theme-data/main.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Main from "@ks/kwai-ui/lib/main";
import _Header2 from "@ks/kwai-ui/lib/theme-data/header.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Header from "@ks/kwai-ui/lib/header";
import _Container2 from "@ks/kwai-ui/lib/theme-data/container.css";
import "@ks/kwai-ui/lib/theme-data/base.css";
import _Container from "@ks/kwai-ui/lib/container";
import "core-js/modules/es.array.includes.js";
import Vue from 'vue';
import LogoVue from './components/Logo.vue';
import RightContent from './components/RightConent.vue';
import { deviceInfo } from '../utils/env';
// 配置一些白名单 path 不显示左侧 menu
var deviceInfoData = deviceInfo();
var SHOW_TOP_PATHS = ['/kwai/recharge', '/recharge/index'];
export default Vue.extend({
  name: 'layout',
  components: {
    LogoVue: LogoVue,
    RightContent: RightContent,
    Container: _Container,
    Header: _Header,
    Main: _Main,
    Row: _Row
  },
  data: function data() {
    return {
      isInPhone: deviceInfoData.isPhone,
      store: this.$store.state,
      showLoginOut: false,
      isInApp: deviceInfoData.isInApp
    };
  },
  computed: {
    logined: function logined() {
      return this.$store.state.login.logined;
    }
  },
  watch: {
    $route: {
      handler: function handler(val) {
        if (SHOW_TOP_PATHS.includes(val.path)) {
          this.showLoginOut = true;
        } else {
          this.showLoginOut = false;
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    loginOut: function loginOut() {
      this.$store.dispatch('login/logout');
    }
  }
});